import React ,{useState}from "react";
import Child from "./Child";

const Parent = () => {
    const [value, setValue] = useState(1);

    const handleValueChange = (newValue) => {
        setValue(newValue);
    }; 
    return (
        <>
            <div>
                <p>Value in Parent Component: {value}</p>
                <Child value={value} onValueChange={handleValueChange} />
            </div>
        </>
    )
}

export default Parent;