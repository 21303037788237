import React from "react";
import { Card } from "react-bootstrap";
const Refund = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12 mt-4">
            <div className="mt-3">
              <h5>Refund Policy:-</h5>
              <p>
                The SWC works transparently. Our Refund policy ensures that all
                the refunds are processed fairly. In case, a student expresses
                his intention of not attending the course, and withdrawing his
                admission, we would require a refund Application / Grievance
                Letter from Parent / Guardian (along with the relationship) to
                be submitted to Branch / Centre in person stating the reason for
                seeking a refund. The date of submission of the written
                application for refund at the Branch / Centre will be considered
                for calculation of the refund amount. Parents/ guardians can
                also request for refund via mail to info@studywavesclasses.com,
                For refund applications via mail, the date of Email will be
                taken into consideration for calculation of the refund amount.
                The refund will be processed only in the “Savings Account” and
                not in any other type of account.
              </p>
            </div>
            <div className="mt-4">
              <h5>1. Refund Policy For Classroom Courses:</h5>
              <ul>
                <li>
                  •<strong>Registration Fee is NOT refundable </strong>under any
                  circumstances whatsoever.
                </li>
                <li>
                  • Short Term Classroom Course Fee / Test Series Fee is NOT
                  refundable under any circumstances whatsoever.
                </li>
                <li>
                  • Fee paid towards Admission Cum Scholarship Test, Studywaves
                  Talent Hunt Exam (SWTHE) & <strong>Immediate</strong> Package
                  for Admission with Scholarship Test (ImPAST) is NOT refundable
                  under any circumstances whatsoever.
                </li>
              </ul>
            </div>

            <div className="mt-4">
              <div className="table-responsive">
              <table
                style={{
                  borderCollapse: "collapse",
                  width: "100%",
                  border: "1px solid #ddd",
                }}
                className="table table-responsive"
              >
                <thead>
                  <tr>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                      Fees Components
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                      Before batch start
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                      Week 1
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                      Week 2
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                      Week 3
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                      Week 4
                    </th>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                      Week 5 or later
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                      Registration Fee
                    </td>
                    <td
                      colSpan={6}
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      Non-Refundable
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                    Tuition Fee
                    </td>

                    <td style={{ border: "1px solid #ddd", padding: "8px" }} >
                    100% Refundable
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }} >
                    90% Refundable
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }} >
                    75% Refundable
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }} >
                    60% Refundable
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }} >
                    50% Refundable
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }} >
                    Non- Refundable
                    </td>
                  </tr>
                </tbody>
              </table>
              </div>
             
            </div>

            <div className="mt-4 mb-5">
              <h5>Note:</h5>
              <ul>
                <li>• Monthly Fees non-refundable under any circumstances</li>
                <li>
                  • Week-1 will be counted from the date of registration by the
                  student or the date of start of the batch, whichever is later.
                </li>
                <li>
                  • Total Payment at Classroom Course eligible for Refund is the
                  sum of Tuition fee, Classroom Service fee, Exam and technology
                  fee Components paid.
                </li>
                <li>
                  • Total course duration is calculated from the Batch start
                  date / Registration date, whichever is later than the Course
                  End date.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Refund;
