import React, { Component, useState } from "react";
import ContactUsForm from "./ContactForm";

const ContactInfo = () => {
  const [info,setInfo] = useState([
    {
      id: 1,
      icon: "fa fa-envelope",
      title: "info@studywavesclasses.com",
    },
    {
      id: 2,
      icon: "fa fa-phone-alt",
      title: "+91 7599666668",
    },
    {
      id: 3,
      icon: "fa fa-map-marker",
      title:
        "Main office Above Jk Foam ,Near Kargaina Police Chowki , badaun Road, Bareily",
    },
  ]);
  return (
    <section className="contact section-padding">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-xl-4 col-lg-5">
            <div className="contact-info-wrapper mb-5 mb-lg-0">
              <h3>
                <span>Get in touch</span>
              </h3>
              <p>
                Have questions about our courses or services? Interested in
                collaborating or partnering with us? We'd love to hear from you!
                Feel free to reach out using any of the methods below:
              </p>

              {info.map((data, i) => (
                <div className="contact-item" key={data.id}>
                  <i className={data.icon}></i>
                  <h5>{data.title}</h5>
                </div>
              ))}
            </div>
          </div>

          <div className="col-xl-7 col-lg-6">
            <ContactUsForm formStyle="contact__form form-row " />
          </div>
        </div>
      </div>
    </section>
  );
};
export default ContactInfo;
