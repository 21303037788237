import React from "react";

import Layout from "../../common/Layout";
import PageBanner from './../../components/layouts/common/PageBanner';
import Studentcourse from "../Components/StudentLayout/Studentcourse";

const StudentCourse = () => {
  return (
    <div className="page">
      <Layout>
        <PageBanner
          title="Contact"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Contact"
        />
        <Studentcourse/>
      </Layout>
    </div>
  );
};

export default StudentCourse