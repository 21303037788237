import React from "react";
import FooterTwo from "../footer/FooterTwo";
import HeaderTwo from "../header/HeaderTwo";
import Terms from "./terms";
const Terms1 = ()=>{
    return(
        <div className="home-page-2">
            <HeaderTwo/>
            <Terms/>
            <FooterTwo/>
        </div>
    )
}

export default Terms1
