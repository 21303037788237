import Cookies from "js-cookie";
import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Nav = () => {
  const containerRef = useRef(null);

  const [user, setUser] = useState(null);

  const userfunction = () => {
    const authToken = Cookies.get("user");
    let userData = null;
    if (authToken && authToken !== "undefined") {
      userData = JSON.parse(authToken);
    }

    // Cookies.remove("user");

    setUser(userData);
  };

  useEffect(() => {
    userfunction();
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      const spanElement = document.createElement("span");
      spanElement.className = "menu-trigger";
      spanElement.innerHTML = '<i class="fal fa-angle-down"></i>';
      containerRef.current.appendChild(spanElement);
    }
  }, []);

  const [menuItems, setMenuItems] = useState([
    {
      label: "Home",
      link: "/",
    },

    {
      label: "Courses",
      link: "#",
      submenu: [
        { label: "Academic", link: "/academic-course" },
        { label: "Competition ", link: "/comptiton-course" },
      ],
    },
    {
      label: "Current Affair",
      link: "/daily-current-affair",
    },
    {
      label: "Download",
      link: "#",
      submenu: [
        { label: "NCERT Books", link: "/ncert-book" },
        { label: "Previous Year Questions", link: "/Previous-year-question" },
        // { label: 'Other', link: '/*' },
      ],
    },

    {
      label: "About",
      link: "/about",
    },
    //   {
    //     label: 'Test Series',
    //     link: '/test-series',

    // },
    {
      label: "Contact",
      link: "/contact",
    },

    // Add more menu items as needed
  ]);
  useEffect(() => {
    setMenuItems((prevMenuItems) =>
      prevMenuItems.map((item) =>
        item.submenu && item.submenu.length > 0
          ? { ...item, hasSubmenu: true, submenuOpen: false }
          : item
      )
    );
  }, []);

  // const handleSubmenuToggle = (index) => {
  //     setMenuItems((prevMenuItems) => {
  //       const newMenuItems = [...prevMenuItems];
  //       newMenuItems[index].submenuOpen = !newMenuItems[index].submenuOpen;
  //       return newMenuItems;
  //     });
  //   };

  const handleSubmenuToggle = (index) => {
    setMenuItems((prevMenuItems) => {
      const newMenuItems = prevMenuItems.map((item, i) => {
        if (i === index) {
          return { ...item, submenuOpen: !item.submenuOpen };
        } else {
          return { ...item, submenuOpen: false }; // Close other submenus
        }
      });
      return newMenuItems;
    });
  };
  return (
    <>
      {user === null || user.userName === "" ? (
        <div className="d-lg-none">
          <button className="btn btn-primary w-100">
            <Link to="/register" style={{ color: "white", fontSize: "18px" }}>
              Login/Register
            </Link>
          </button>
        </div>
      ) : user.userType === "admin" ? (
        <div className="d-lg-none">
          <button className="btn btn-primary w-100">
            <Link to="/admin" style={{ color: "white", fontSize: "18px" }}>
              My Dashboard
            </Link>
          </button>
        </div>
      ) : (
        <div className="d-lg-none">
          <button className="btn btn-primary w-100">
            <Link to="/user-header" style={{ color: "white", fontSize: "18px" }}>
              My Dashboard
            </Link>
          </button>
        </div>
      )}

      <ul className="primary-menu">
        {menuItems.map((item, index) => (
          <li key={index}>
            <Link to={item.link}>{item.label}</Link>

            {item.submenu && (
              <span
                className={`menu-trigger ${item.submenuOpen ? "open" : ""}`}
                onClick={() => handleSubmenuToggle(index)}
              >
                <i
                  className={`submenu-icon ${
                    item.submenuOpen ? "fal fa-angle-up" : "fal fa-angle-down"
                  }`}
                ></i>
              </span>
            )}
            {item.submenu && (
              <ul className={`submenu ${item.submenuOpen ? "open" : ""}`}>
                {item.submenu.map((subitem, subindex) => (
                  <li key={subindex}>
                    <Link to={subitem.link}>{subitem.label}</Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </>
  );
};
export default Nav;
