import React, { useState,useEffect } from 'react';
import Slider from "react-slick";
import { HomeTwoTestimonial } from '../../../utils/script';
import base_url from '../../../api/bootapi';
import axios from 'axios';

const Review = ()=>{

    const [review,setReview] = useState([]);
    const [reviewImage,setReviewImage] = useState([]);

    useEffect(()=>{
        getReviewDataFromServer();
    },[]);

    const getReviewDataFromServer = async ()=>{
        try{
                const response = await axios.get(`${base_url}/Testimonial`);
                const reviewData = response.data;
                setReview(reviewData);
                const imagePromises = reviewData.map(async (data)=>{
                    const imageResponse = await axios.get(`${base_url}/image/TestimonialImage/${data.rwid}`,{
                        responseType:'arraybuffer',
                    });
                    const blob = new Blob([imageResponse.data],{type:imageResponse.headers["content-type"]});
                    const dataUrl = blobTODataUrl(blob);
                    return dataUrl;
                });

                const imageResult = await Promise.all(imagePromises);
                setReviewImage(imageResult);
                
            }catch(error){
                console.log("Some error is occured to fetching the data of Review "+error);
            }
        
    }

    const blobTODataUrl = (blob)=>{
        return new Promise((resolve,reject)=>{
            const reader = new FileReader();
            reader.onloadend =()=>{
                resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }


        return (

            <section className="testimonial section-padding">
                <div className="container-fluid container-grid">
                    <div className="row justify-content-center">
                        <div className="col-xl-6">
                            <div className="section-heading text-center mb-50">
                                <span className="subheading">Students Feedback</span>
                                <h2 className="font-lg">Our Students Says</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-12 col-xl-12">
                             <div className="testimonials-slides-3">
                            <Slider {...HomeTwoTestimonial} >
                            {
                                review.map((data,i) => (

                                <div className="testimonial-item" key={data.rwid}>
                                    <div className="testimonial-inner">
                                    <div className="quote-icon" style={{marginTop:'-2rem'}} ><i className="flaticon-left-quote" style={{fontSize:'1rem'}}></i></div>
                                        
                                            <div className="testimonial-text mb-30">
                                                {data.description}
                                            </div>

                                            <div className="client-info d-flex align-items-center">
                                                <div className="client-img">
                                                    <img src={reviewImage[i]} alt="" className="img-fluid"/>
                                                </div>
                                                <div className="testimonial-author">
                                                    <h4>{data.studentName}</h4>
                                                    
                                                </div>
                                            </div>
                                    </div>
                                </div>
                                    ))
                                }
                                
                            </Slider>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    
}

export default Review;
