import React, { useState,useEffect } from "react";
import CourseTypeOne from "./CourseTypeOne";
// import CourseData from "../../../data/course/CourseData.json";
import base_url from "../../../api/bootapi";
import axios from "axios";
import { useLocation } from "react-router-dom";
const CourseGrid = () => {
  const [displayedCourses, setDisplayedCourses] = useState(8);
  const [courseData,setCourseData] = useState([]);
  const location = useLocation();
  const param =new URLSearchParams(location.search);
  const catcd = param.get("catcd")!=null?param.get("catcd"):""
  const handleLoadMore = () => {
    setDisplayedCourses((prevDisplayedCourses) => prevDisplayedCourses + 4);
  };
  const CourseItems = courseData.slice(0, displayedCourses);
  useEffect(() => {
    fetchCourse();
  },[]);


const fetchCourse = ()=>{

  const url = catcd!=""?`${base_url}/Course/Category/${catcd}`:`${base_url}/Course`;
  axios.get(url).then((response)=>{

    const lessions =  response.data.map((lsn)=>({
      ...lsn,
      imageUrl: `${base_url}/Course/CourseImage/${lsn.rwid}` 
  }));
      setCourseData(lessions);
  }).catch((error=>{
    console.log("error is occured to fetching the course "+error);
  }))
}
  return (
    <section className="section-padding page">
      <div className="container">
        <div className="row">
          {CourseItems.map((item) => (
            <div
              className="course-item col-lg-3 col-md-6 col-sm-6"
              key={item.rwid}
            >
              <CourseTypeOne data={item} />
            </div>
          ))}
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            className="btn btn-main-2 btn-sm-2 rounded "
            onClick={handleLoadMore}
          >
            <i
              className="fa fa-spinner"
              aria-hidden="true"
              style={{ marginRight: "1rem" }}
            ></i>
            Load More
          </div>
        </div>
      </div>
    </section>
  );
};

export default CourseGrid;
