import React from "react";

import Layout from "../../common/Layout";
import PageBanner from './../../components/layouts/common/PageBanner';
import Layout123 from "../Components/Layout/Layout";

const UserHeader = () => {
  return (
    <div className="page">
      <Layout>
        <PageBanner
          title="Dashboard"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Dashboard"
        />
        <Layout123 />

      </Layout>
    </div>
  );
};

export default UserHeader;
