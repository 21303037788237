import React from "react";

import Layout from "../../common/Layout";
import PageBanner from './../../components/layouts/common/PageBanner';
import Dashboard from "../Components/DashboardLayout/DashboardLayout";

const StudentDashboard = () => {
  return (
    <div className="page">
      <Layout>
        <PageBanner
          title="Contact"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Contact"
        />
        <Dashboard/>
      </Layout>
    </div>
  );
};


export default StudentDashboard