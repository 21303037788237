import React , { useState } from 'react';
import emailjs from 'emailjs-com';
import axios from 'axios';
import base_url from '../../../api/bootapi';

const Result = () => {
    return (
        <p className="success-message">Thanks for your query. We will contact with you soon.</p>
    )
}

function ContactUsForm( { formStyle } ) {
    const[contact,setContact]=useState([]);

    const [result, setResult] = useState( false );

    const sendEmail = ( e ) => {

        e.preventDefault();

        axios.post(`${base_url}/ContactUs`,contact).then((response)=>{

            
            emailjs
            .sendForm(
                'service_bxh6md3', 
                'template_1g7v07n', 
                e.target, 
                'user_8Lx0gfI1ktOoeEN8DTV10'
            )
            .then( ( result ) => {
                console.log( result.text );
                }, 
                ( error ) => {
                    console.log( error.text );
                }
            );
            e.target.reset();
            setResult( true );
            setContact({
                name:"",
                email:"",
                subject:"",
                message:""

            })
            
        }).catch((error)=>{
            console.log("Some error is occured to store the contact in database "+error)
        })  
      
    };

    setTimeout(() => {
        setResult( false );
    }, 10000);


   


    return (
        <form className={`${ formStyle }`} action="" onSubmit={ sendEmail }>
            <div className="row">
                <div className="col-lg-6">
                    <div className="form-group">
                        <input type="text" id="name" name="name" value={contact.name} onChange={(e)=>{
                            setContact({...contact,name:e.target.value})
                        }} className="form-control" placeholder="Your Name"/>
                    </div>
                </div>
                
                <div className="col-lg-6">
                    <div className="form-group">
                        <input type="text" name="email" id="email" value={contact.email} onChange={(e)=>{
                            setContact({...contact,email:e.target.value})
                        }} className="form-control" placeholder="Email Address"/>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="form-group">
                        <input type="text" name="subject" id="subject" value={contact.subject} onChange={(e)=>{
                            setContact({...contact,subject:e.target.value})
                        }} className="form-control" placeholder="Subject"/>
                    </div>
                </div>
                
                <div className="col-lg-12">
                    <div className="form-group">
                        <textarea id="message" name="message" cols="30" rows="6"
                        value={contact.message} onChange={(e)=>{
                            setContact({...contact,message:e.target.value})
                        }}
                         className="form-control" placeholder="Your Message"></textarea>    
                    </div>
                </div>

                <div className="col-lg-12">
                    <div className="text-center">
                        <button className="btn btn-main w-100 rounded" type="submit">Send Message</button>
                    </div>
                </div>

                { result ? <div className="form-group"><Result /></div>  : null }
            </div>

        </form>
    )
}
export default ContactUsForm;