import React from "react";
import FooterTwo from "../footer/FooterTwo";
import HeaderTwo from "../header/HeaderTwo";
import Refund from "./refund";
const Refund1 = ()=>{
    return(
        <div className="home-page-2">
            <HeaderTwo/>
            <Refund/>
            <FooterTwo/>
        </div>
    )
}

export default Refund1
