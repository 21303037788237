// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.myimg{
    height:22rem;
    width:20rem;
}
  @media (max-width: 768px) {
    .myimg{
        height:22rem;
        width:20rem;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/assest/css/about.css"],"names":[],"mappings":";AACA;IACI,YAAY;IACZ,WAAW;AACf;EACE;IACE;QACI,YAAY;QACZ,WAAW;IACf;EACF","sourcesContent":["\r\n.myimg{\r\n    height:22rem;\r\n    width:20rem;\r\n}\r\n  @media (max-width: 768px) {\r\n    .myimg{\r\n        height:22rem;\r\n        width:20rem;\r\n    }\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
