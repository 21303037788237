import React from 'react'
import Menu from './Navside';
import menuItems from './Menus'; 

const Layout = () => {
  return (

    <div className="py-4 bg-f9fbff">
      <div className="container">
        <div className="row">
          <div className="col-md-3 col-lg-3 col-sm-12">
            <div className="td-sidebar ">
              <Menu menuItems={menuItems} />
            </div>
          </div>
          <div className="col-md-9 col-lg-9 col-sm-12 d-none d-md-block">
            <div>Welcome to Study Waves</div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default Layout