import React from 'react';
import comingsoon from './../../assest/img/comingsoon.png';

const Comingsoon = () => {
  const containerStyle = {
    backgroundColor: 'gray',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh', 
  };

  const imageStyle = {
    maxWidth: '100%', 
    height:"20rem" 
  };

  return (
    <div style={containerStyle}>
      <img
        src={comingsoon}
        alt="Coming Soon"
        style={imageStyle}
      />
    </div>
  );
};

export default Comingsoon;
