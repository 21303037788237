

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import pdf from "./../../../assest/pdf/law.12.pdf";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import base_url from "../../../api/bootapi";

const CurrentAffairsone = () => {
  const [loading, setLoading] = useState(true);
  const [currentAffairData, setCurrentAffairData] = useState([]);

  useEffect(() => {
    fetchCurrentAffairsFromServer();
  }, []);

  const openPdfInNewTab = (pdfLink) => {
    const newTab = window.open(pdfLink, "_blank");
    if (newTab) {
      newTab.focus();
    }
  };

  const fetchCurrentAffairsFromServer = async ()=>{
    
    try{
      const response  = await axios.get(`${base_url}/CurrentAffair`);
      const currentAffairs = response.data.map((curr)=>({
        ...curr,
        currTitle: new Date(curr.currentdt).toLocaleDateString('en-GB',{
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }),
        pdfLink:`${base_url}/CurrentAffair/getPdf/${curr.rwid}`
      }));


      const categorizedData = {};

  // Process each item in the currentAffairs array
  currentAffairs.forEach((curr) => {
    const currentDate = new Date(curr.currentdt);
    const currentMonth = currentDate.toLocaleDateString('en-US', { month: 'long' });
    const currentYear = currentDate.getFullYear();

    // Create a unique key for each combination of month and year
    const key = `${currentMonth} ${currentYear}`;

    // If the key doesn't exist in the categorizedData object, create an array for it
    if (!categorizedData[key]) {
      categorizedData[key] = [];
    }

    // Push the current item to the array corresponding to the key
    categorizedData[key].push(curr);
  });

  console.log(categorizedData);
  setCurrentAffairData(categorizedData);
  setLoading(false);


    }catch(error){
      console.log("Some error is occured to fetching the current affairs "+error);
      setLoading(false);
    }
  }

  return (
    <section className="section-padding page">
      <div className="course-top-wrap">
        <div className="container">

          {loading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <>
              {Object.entries(currentAffairData).map(([month, monthData]) => (
                <div key={month}>
                  <h4 style={{ textAlign: 'center' }} className="mt-1 mb-2"> {month}</h4>
                  <div className="row">
                    {monthData.map((item, i) => (
                      <div key={i} className="col-xl-2 col-lg-2 col-md-4">
                        <div className="single-course-category style-2">
                          <div className="course-cat-icon"></div>
                          <div className="course-cat-content">
                            <h4 className="course-cat-title" >
                              <Link to="#" onClick={() => openPdfInNewTab(item.pdfLink)}>
                                {item.currTitle}
                              </Link>
                            </h4>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </>
          )}

        </div>
      </div>
    </section>
  );
};

export default CurrentAffairsone;

