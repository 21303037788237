import React, { useEffect, useState } from "react";
import {Link } from "react-router-dom"; // Assuming you are using react-router-dom for the Link component
import base_url from "../../../api/bootapi";
import axios from "axios";
import { Input, FormGroup } from "reactstrap";
import Swal from "sweetalert2";

const RegisterForm = () => {
  useEffect(() => {
    // alert("rahul with krishana")
    // toast.error("rahul");
    // toast("Wow so easy!");
  },[]);

  const [usernameAvailability, setUsernameAvailability] = useState("");
  const [emailAvailability, setEmailAvailability] = useState("");
  const [userDetails, setUserDetails] = useState({
    userName:"",
    password:"",
    confirmPassword:"",
    email:""
  });
  //form handler form
  const handleForm = async (e) => {
    // console.log(user)
    e.preventDefault();

    if (!userDetails.userName || !userDetails.email || !userDetails.password || !userDetails.confirmPassword) {
      Swal.fire({ icon: 'error', title: 'Error!', text: 'Please fill all fields' });
      return;
    }else if(userDetails.password!=userDetails.confirmPassword){
      Swal.fire({ icon: 'error', title: 'Error!', text: 'Password and Confirm password are not same'});
      return;
    }

    const usernameResponse = await axios.get(
      `${base_url}/UserDetailsByUserName/${userDetails.userName}`
    );
    setUsernameAvailability(usernameResponse.data);

    // Check email availability
    const emailResponse = await axios.get(
      `${base_url}/UserDetailsByEmail/${userDetails.email}`
    );
    setEmailAvailability(emailResponse.data);

    if (usernameResponse.data === "Username already exists") {
      Swal.fire({icon: 'error',title: 'Error!',text: 'Username already exists',});
      return;
    }else if(emailResponse.data === "Email already exists"){
      Swal.fire({icon: 'error',title: 'Error!',text: 'Email already exists',});
      return;
    }else if(usernameResponse.data === "Username is available" && emailResponse.data === "Email is available"){
      postDataToServer(userDetails);
    
    }
  };

  // creating function to post data on server
  const postDataToServer = (userDetails) => {
    console.log(userDetails);
    axios.post(`${base_url}/UserDetails`,userDetails).then(
      (response) => {
        console.log(response.data);
        Swal.fire({icon: 'success',title: 'Success!',text: 'User is added successfully',});
        setUserDetails({
          userName: "",
          email: "",
          password: "",
          confirmPassword: "",
          acceptTerms: false,
        });
      },     
      (error) => {
        Swal.fire({icon: 'error',title: 'error!',text: 'Something went wrong',});
      }
    );

  };

  return (
    <section className="page-wrapper single ">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6 col-xl-6">
            <div className="login-form">
              <div className="form-header">
                <h2 className="font-weight-bold mb-3">Sign Up</h2>   
                <div className="info">
                  <span>Already have an account? </span>
                  <Link to="/login" className="text-decoration-underline">
                    Sign In
                  </Link>
                </div>
              </div>

              <form
                onSubmit={handleForm}
                className="login login-register-form "
                method="post"
              >
                <FormGroup>
                  <label>
                    Username &nbsp;<span className="required">*</span>
                  </label>
                  <Input
                    type="text"
                    placeholder="Username"
                    name="userName"
                    id="userName"
                    value={userDetails.userName}
                    onChange={(e) => {
                      setUserDetails({ ...userDetails, userName: e.target.value });
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <label>
                    Email address&nbsp;<span className="required">*</span>
                  </label>
                  <Input
                    type="email"
                    placeholder="email"
                    name="email"
                    id="email"
                    value={userDetails.email}
                    onChange={(e) => {
                      setUserDetails({ ...userDetails, email: e.target.value });
                    }}
                  />
                </FormGroup>

                <FormGroup>
                  <label>
                    Password&nbsp;<span className="required">*</span>
                  </label>
                  <Input
                    type="password"
                    placeholder="password"
                    name="password"
                    id="password"
                    value={userDetails.password}
                    onChange={(e) => {
                      setUserDetails({ ...userDetails, password: e.target.value });
                    }}
                  />
                </FormGroup>

                <FormGroup>
                  <label>
                    Password&nbsp;<span className="required">*</span>
                  </label>
                  <Input
                    type="password"
                    placeholder="Re-Enter Password"
                    name="confirmPassword"
                    id="confirmPassword"
                    value={userDetails.confirmPassword}
                    onChange={(e) => {
                      setUserDetails({ ...userDetails, confirmPassword: e.target.value });
                    }}
                  />
                </FormGroup>
                <div className="form-row py-2">
                  <input type="checkbox" />{" "}
                  <span>Accept the Terms and Privacy Policy</span>
                </div>
                <div className="form-row">
                  <button type="submit">Register</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RegisterForm;
