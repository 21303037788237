import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import mts from "./../../../assest/ComptitionImages/sscmts.jpeg";

const CourseTypeOne = (data) => {
  return (
    <div className={`course-style-5 bg-white `}>
      <div class="course-header">
        <div class="course-thumb">
          <img src={data.data.imageUrl} alt={`Course Thumb${data.data.rwid}`} class="img-fluid" />
          <div class="course-price">
            {data.data.price == "0.00" ? (
              <div className="price current-price">Free</div>
            ) : (
              <div className="price current-price">{data.data.price} rs</div>
            )}
            {data.data.oldPrice && (
              <div className="ms-1 price old-price">{data.data.oldPrice} rs</div>
            )}
          </div>
        </div>
      </div>

      <div className="course-content">
        <div className="course-meta meta-style-1">
          <span className="lessons">
            <i className="far fa-play-circle me-2"></i>
            {data.data.lesson} Lectures
          </span>
          <span className="label">{data.data.level}</span>
        </div>

        <h4>
          <Link to={process.env.PUBLIC_URL + `/course-details/${data.data.rwid}`}>
            {data.data.title}
          </Link>
        </h4>

        <div className="course-footer  d-flex align-items-center justify-content-between">
          <span className="students">
            <i className="far fa-user-alt me-2"></i>
            {data.data.student} Students
          </span>
          <Link
            to={process.env.PUBLIC_URL + `/course-details/${data.data.rwid}`}
            className="rounded-btn"
          >
            <i className="fa fa-long-arrow-right"></i>
          </Link>
        </div>

      </div>
    </div>
  );
};
export default CourseTypeOne;
