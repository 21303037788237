import React from 'react';
import { Slider, Counter, CourseCat01,Process,CourseGridPopular, Features,About,Review,Cta,Teacher}  from '../layouts/home02/index';
import HeaderTwo from '../../common/header/HeaderTwo';
import FooterTwo from '../../common/footer/FooterTwo';

const HomeTwo =() =>  {
    return (
        <div className="home-page-2">       
            <HeaderTwo />       
                    <Slider />
                    <CourseCat01 />
                    <Counter />
                    <Teacher />
                    <Review />
                    <FooterTwo />
        </div>
    );
}

export default HomeTwo;