import Banner from './Banner';
import Counter from './Counter';
import CourseCat01 from './CourseCat';
import Process from './Process';
import CourseGridPopular from './CourseGridPopular';
import Features from './Features';
import About from './About';
import Review from './Review';
import Cta from './Cta';
import Teacher from './Teacher';
import Slider from './Slider';

export {Banner,CourseCat01,Counter,Process,CourseGridPopular,Features,About,Review,Cta,Teacher,Slider}