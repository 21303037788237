import React from 'react';
import { PageBanner}  from '../layouts/course02/index';
import CurrentAffairone  from '../layouts/CurrentAffair/CurrentAffairsone';
import Layout from '../../common/Layout';

const CurrentAffair = () =>  {
    return (
        <div className="page">       
            <Layout>     
                <PageBanner
                    title="Current-Affair"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Current-Affair"
                    />
               <CurrentAffairone/>
            </Layout>  
        </div>
    );
}

export default CurrentAffair
 