import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Navside.css'
import Cookies from 'js-cookie';
 


const Navside = ({ menuItems }) => {
  const navigate = useNavigate();
  const handleClick = ()=>{
    Cookies.remove('user');
    navigate("/");   
  }
  return (
    <ul>
      {menuItems.map((item, index) => (
        <li key={index}>
          <Link to={item.url}>{item.label}</Link>
        </li>
      ))}
      <li className="mt-4">
        <button className="btn btn-outline-danger rounded-pill w-100 mb-2" onClick={handleClick}>Log Out</button>
      </li>
    </ul>
  );
}

export default Navside;
