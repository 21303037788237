// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.td-sidebar {
    background-color: #f8f9f8;
    border-top: 3px solid #3e4192;
    border-radius: 3px 3px 0 0;
}


.td-sidebar ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.td-sidebar ul li {
    border-bottom: 1px solid #f1f1f1;
    margin-top: -1px;
}
.td-sidebar ul li a {
    padding: 15px 20px;
    display: block;
}

`, "",{"version":3,"sources":["webpack://./src/Student/Components/DashboardLayout/Style.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,6BAA6B;IAC7B,0BAA0B;AAC9B;;;AAGA;IACI,qBAAqB;IACrB,UAAU;IACV,SAAS;AACb;;AAEA;IACI,gCAAgC;IAChC,gBAAgB;AACpB;AACA;IACI,kBAAkB;IAClB,cAAc;AAClB","sourcesContent":[".td-sidebar {\r\n    background-color: #f8f9f8;\r\n    border-top: 3px solid #3e4192;\r\n    border-radius: 3px 3px 0 0;\r\n}\r\n\r\n\r\n.td-sidebar ul {\r\n    list-style-type: none;\r\n    padding: 0;\r\n    margin: 0;\r\n}\r\n\r\n.td-sidebar ul li {\r\n    border-bottom: 1px solid #f1f1f1;\r\n    margin-top: -1px;\r\n}\r\n.td-sidebar ul li a {\r\n    padding: 15px 20px;\r\n    display: block;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
