import logo from './logo.svg';
import './App.css';
import React from 'react';
import { Route, BrowserRouter,Routes} from 'react-router-dom';


import HomeTwo from './components/pages/Home02';
import AboutMain from './components/pages/About';
import CourseOne from './components/pages/CourseOne';
import CourseTwo from './components/pages/CourseTwo';
import CourseThree from './components/pages/CourseThree';
import CourseFour from './components/pages/CourseFour';
import CourseList from './components/pages/CourseList';

import CourseDetails from './components/pages/CourseDetails';
import CourseDetailsTwo from './components/pages/CourseDetailsTwo';

import InstructorOne from './components/pages/InstructorPage';


import Login from './components/pages/Login';
import Register from './components/pages/Register';

import ContactPage from './components/pages/ContactPage';
import BlogPageOne from './components/pages/BlogPageOne';
import TeacherDetails from './components/layouts/TeacherDetails/TeacherDetails';

import Header from './Admin/Header/Header';
import UserHeader from './Student/Header/UserHeader';
import CurrentAffair from './components/pages/CurrentAffair';
import PreviousyearCategory from './components/pages/PreviousyearCategory';
import Class from './components/userpages/Class';
import Subject from './components/userpages/Subject';
import Comingsoon from './common/Comingsoon/Comingsoon';
import StudentCourse from './Student/Pages/StudentCourse';
import StudentDashboard from './Student/Pages/StudentDashboard';
import Testseries from './TestSeries/Testseries';
import Child from './DemoCode/Child';
import Parent from './DemoCode/Parent';
import Lession from './components/userpages/Lession';
import PreviousYearquestion from './components/layouts/PreviousYear/PreviousYearquestion';
import Previousquestionpaper from './components/layouts/PreviousYear/PreviousYearquestionPage2';
import Terms1 from './common/Documents/terms1';
import Refund1 from './common/Documents/refund1';
import Legal1 from './common/Documents/legal1'; 
function App() {

  return ( 
      <BrowserRouter>  
          <Routes>

              {/* demo */}

              <Route exact path="child" element={<Child/>}/>
              <Route exact path="parent" element={<Parent/>}/>
              <Route exact path="*" element={<Comingsoon/>}/>
              <Route exact path="/" element={<HomeTwo/>}/>

              {/* student  */}
              <Route exact path="/user/dashboard" element={<StudentDashboard/>}/>
              <Route exact path="/user/student-course" element={<StudentCourse/>}/>
              
              {/* Test Series  */}
              <Route exact path="/test-series" element={<Testseries/>}/>


              {/* User  */}
              <Route exact path="/daily-current-affair" element={<CurrentAffair/>}/>
              <Route exact path="/Previous-Year-Question" element={<PreviousyearCategory/>}/>
              <Route exact path="/Previous-Year-QuestionPaper" element={<Previousquestionpaper/>}/>
              <Route exact path="/ncert-book" element={<Class/>}/>
              <Route exact path='/about' element={<AboutMain/>}/>
              <Route exact path="/academic-course" element={<CourseOne />} />
              <Route exact path="/subject/:classid" element={<Subject />} />
              <Route exact path="/lession" element={<Lession/>}/>
              <Route exact path="/comptiton-course" element={<CourseOne />} />
              <Route exact path="/teacher" element={<TeacherDetails/>}/>
              <Route exact path="/admin" element={<Header/>}/>   
              <Route exact path="/user-header" element={<UserHeader/>}/>
              <Route exact path='/terms' element={<Terms1/>} />
              <Route exact path='/refund' element={<Refund1/>} />
              <Route exact path='/legal' element={<Legal1/>} />
              <Route exact path={`${process.env.PUBLIC_URL + '/course-3'}`} element={<CourseThree/>}/>
              <Route exact path={`${process.env.PUBLIC_URL + '/course-4'}`} element={<CourseFour/>}/>
              <Route exact path={`${process.env.PUBLIC_URL + '/course-list'}`} element={<CourseList/>}/>
              <Route exact path={`${process.env.PUBLIC_URL + '/course-details/:id'}`} element={<CourseDetails/>}/>
              <Route exact path={`${process.env.PUBLIC_URL + '/course-details-two/:id'}`} element={<CourseDetailsTwo/>}/>
              <Route exact path={`${process.env.PUBLIC_URL + '/instructors'}`} element={<InstructorOne/>}/>
              <Route exact path={`${process.env.PUBLIC_URL + '/login'}`} element={<Login/>}/>
              <Route exact path={`${process.env.PUBLIC_URL + '/register'}`} element={<Register/>}/>
              <Route exact path={`${process.env.PUBLIC_URL + '/contact'}`} element={<ContactPage/>}/>
              <Route exact path={`${process.env.PUBLIC_URL + '/blog'}`} element={<BlogPageOne/>}/>

           </Routes>

      </BrowserRouter>
  );
}

export default App;

