import React, { useState,useEffect } from "react";
import image from "./../../../assest/img/teacher.jpg";
import { Link } from "react-router-dom";
import pdf from "./../../../assest/pdf/law.12.pdf";
import axios from "axios";
import base_url from "../../../api/bootapi";
const PreviousYearquestion = () => {

  const[images,setImages]=useState([]);
  const[category,setCategory]=useState([ ])
    useEffect(()=>{
      getCategoryFromServer();
  },[]);

  
  const getCategoryFromServer = async ()=>{
    try{
        const response = await axios.get(`${base_url}/Category`);
        const cat = response.data;
        setCategory(cat);
        const imagePromises = cat.map(async (category)=>{
            const imageResponse = await axios.get(`${base_url}/image/CategoryImage/${category.rwid}`,{
                responseType:'arraybuffer'
            });
            const blob =new Blob([imageResponse.data],{type:imageResponse.headers['content-type']});
            const dataUrl = await blobToDataUrl(blob);
            
            return dataUrl;
        });

        const imageResults = await Promise.all(imagePromises);
        setImages(imageResults);
        console.log();
        
    }catch(error){
        console.log("Some error is occured to fetching the category details "+error)
    }
};

const blobToDataUrl = (blob)=>{
    return new Promise((resolve,reject)=>{
        const reader = new FileReader();
        reader.onloadend = () => {
            resolve(reader.result);
          };
          reader.onerror = reject;
          reader.readAsDataURL(blob);
    });
};

  return (
    <section className="section-padding page">
      <div className="course-top-wrap">
        <div className="container">
          <div className="row">
            {category.map((item, i) => (
              <div className=" col-lg-6 col-md-6">
                <div className="single-course-category style-2 ">
                  <div className="course-cat-icon">
                    <img src={images[i]} alt="" className="img-fluid" />
                  </div>
                  <div className="course-cat-content">
                    <h4
                      className="course-cat-title "
                      style={{ marginLeft: "2rem" }} 
                    >
                      <Link to={`/Previous-Year-QuestionPaper?catcd=${item.catcd}`}>
                        {item.name}
                      </Link>
                    </h4>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
export default PreviousYearquestion;
