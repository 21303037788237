import Cookies from 'js-cookie';
import React from 'react'

const Header = () => {
  // Cookies.remove('user');
  return (
    <div>
       header
    </div>
  )
} 

export default Header
