import React from "react";
import FooterTwo from "../footer/FooterTwo";
import HeaderTwo from "../header/HeaderTwo";
import Legal from "./legal";
const Legal1 = ()=>{
    return(
        <div className="home-page-2">
            <HeaderTwo/>
            <Legal/>
            <FooterTwo/>
        </div>
    )
}

export default Legal1
