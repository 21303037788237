import React, { Component, useEffect, useState } from 'react';
import Slider from "react-slick";
import { HomeTwoTestimonial } from '../../../utils/script';
import Teacherimage from './../../../assest/img/teacher.jpg';
import { Link } from 'react-router-dom';
import axios from 'axios';
import base_url from '../../../api/bootapi';

const Teacher=()=>  {

    const [teacher,setTeacher]  = useState([]);

       useEffect(()=>{
        getTeachersFromServer();
       },[]);
    
       const [teacherImage,setTeacherImage]=useState([]);

    const getTeachersFromServer = async ()=>{

        try{
            const response = await axios.get(`${base_url}/Teacher`);
            const teacherdata = response.data;

            setTeacher(teacherdata);
            const imagePromises = teacherdata.map(async (t)=>{
                const imageResponse = await axios.get(`${base_url}/image/TeacherImage/${t.rwid}`,{
                    responseType:'arraybuffer',
                });
                const blob = new Blob([imageResponse.data],{type: imageResponse.headers['Content-Type']});
                const dataUrl = await blobToDataUrl(blob);
                return dataUrl;   
            });

            const imageResults = await Promise.all(imagePromises);
            setTeacherImage(imageResults);


        }catch(error){
            console.log("Some error is occured to fetching the teacher data "+error);
        }
    }

    const blobToDataUrl = (blob)=>{
        return new Promise((resolve,reject)=>{
            const reader = new FileReader();
            reader.onloadend = ()=>{
                resolve(reader.result);
            };
            reader.onerror = reject; 
            reader.readAsDataURL(blob); 
        })
    }

   
        return (

            <section className="testimonial section-padding">
                <div className="container-fluid container-grid">
                    <div className="row justify-content-center">
                        <div className="col-xl-6">
                            <div className="section-heading text-center mb-10">
                                <h2 className="font-lg">Meet Our Team</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-12 col-xl-12">
                             <div className="testimonials-slides-3">
                            <Slider {...HomeTwoTestimonial} >
                            {
                                teacher.map((data,i) => (

                                <div className="testimonial-item" key={data.rwid}>
                                    <div className="testimonial-inner">
                                            <div className=" mb-20"   >
                                               <img style={{borderRadius:'0.5rem'}} src={teacherImage[i]} alt="" className="img-fluid"/>
                                            </div>

                                            <div className="client-info d-flex align-items-center justify-content-between">
                                               
                                                <div className="testimonial-author">
                                                    <h4>{data.name}</h4>
                                                    <span className="meta">{data.subject}</span>
                                                </div>
                                                
                                                <Link to={process.env.PUBLIC_URL + `/teacher?rwid=${data.rwid}`} className="rounded-btn">
                                                       <i className="fa fa-long-arrow-right"></i>
                                                </Link>
                                                
                                            </div>
                                    </div>
                                </div>
                                    ))
                                }
                            </Slider>
                            </div>
                        </div>
                    </div>   
                </div>
            </section>

           
       
        );
    
}

export default Teacher;
