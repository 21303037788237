import React, { Component } from "react";
import TestOne from "./TestCard";
import CourseData from "../data/course/CourseData.json";


class TestCard extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      displayedCourses: 8, 
    };
  }

  handleLoadMore = () => {
    this.setState((prevState) => ({
      displayedCourses: prevState.displayedCourses + 4, 
    }));
  };

  render() {
    const { displayedCourses } = this.state;
    const CourseItems = CourseData.slice(0, displayedCourses);

    return (
      <section className="section-padding page">
        {/* ... Your existing code ... */}
        <div className="container">
          <div className="row ">
            {CourseItems.map((item) => (
              <div
                className="course-item col-lg-3 col-md-6 col-sm-6"
                key={item.id}
              >
                <TestOne data={item} />
              </div>
            ))}
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              className="btn btn-main-2 btn-sm-2 rounded "
              onClick={this.handleLoadMore}
            >
              <i
                class="fa fa-spinner"
                aria-hidden="true"
                style={{ marginRight: "1rem" }}
              ></i>
              Load More
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default TestCard;
