import React, { useState, useEffect } from "react";
import { Card, Row, Col, Spinner, Form } from "react-bootstrap";
import class1 from "./../../../assest/classImages/question-Paper.jpeg";
import { PageBanner } from "../../layouts/courses/index";
import Layout from "../../../common/Layout";
import { Link } from "react-router-dom/dist";
import { useLocation } from "react-router-dom";
import axios from "axios";
import base_url from "../../../api/bootapi";

const Previousquestionpaper = () => {
  const [question, setQuestion] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const catcd = params.get("catcd");
  let date = '2022'

  const openPdfInNewTab = async (pdfLink) => {
    try {
      const response = await fetch(pdfLink);

      if (!response.ok) {
        throw new Error(`Failed to fetch PDF. Status: ${response.status}`);
      }

      const blob = await response.blob();
      const dataUrl = URL.createObjectURL(blob);
      // Open the PDF in a new tab
      const newTab = window.open(dataUrl, "_blank");

      if (newTab) {
        newTab.document.title = title || "PDF Document";
        newTab.focus();
      }
    } catch (error) {
      console.error(`Error opening PDF: ${error.message}`);
    }
  };

  useEffect(() => {
    fetchData();
  }, [catcd]);

  const fetchData = async () => {
    try {
      console.log("datte value"+date)
      const response = await axios.get(
        `${base_url}/PreviousYearQuestionByCatcd/${catcd}/${date}`
      );
      const lessions = response.data.map((lsn) => ({
        ...lsn,
        imageUrl: class1,
        pdfLink: `${base_url}/image/PreviousYearQuestion/${lsn.rwid}`,
      }));    
      setQuestion(lessions);
      setLoading(false);
    } catch (error) {
      console.log(
        "Some error is occured to fetching the data of Lession " + error
      );
      setLoading(false);
    }
  };


  const handleSelectChange = (event) => {
    date = event.target.value
    fetchData();
  };

  return (
    <div className="page">
      <Layout>
        <PageBanner
          title="Academic Course"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Previous Year Question"
        />
        <div
          className="container"
          style={{ marginTop: "4rem", marginBottom: "4rem" }}
        >
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: "300px" }}
            >
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <Row>
              <Row>
                <div className="mb-3 col-md-4">
                  {/* Previous-Year-QuestionPaper?catcd=CAT00007 */}
                  <Form.Select
                    className="years"
                    id="years"
                    onChange={handleSelectChange}
                  >
                      <option value={'2019'}>2019</option>
                      <option value={'2020'}>2020</option>
                      <option value={'2021'}>2021</option>
                      <option value={'2022'} selected>2022</option>
                      <option value={'2023'}>2023</option>
                  </Form.Select>
                </div>
              </Row>

              {question.map((questionItem) => (
                <Col
                  key={questionItem.rwid}
                  md={4}
                  sm={6}
                  xs={12}
                  className="mb-4"
                >
                  <Link
                    to={`/Previous-Year-QuestionPaper?catcd=${catcd}`}
                    onClick={() => openPdfInNewTab(questionItem.pdfLink)}
                  >
                    <Card>
                      <div className="d-flex">
                        <div style={{ flex: "30%", overflow: "hidden" }}>
                          <Card.Img
                            src={questionItem.imageUrl}
                            alt={`Subject ${questionItem.lession}`}
                            style={{ width: "100%" }}
                          />
                        </div>

                        <div style={{ flex: "70%" }}>
                          <Card.Body
                            style={{
                              height: "60px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Card.Title>
                              {questionItem.questionTitle}
                            </Card.Title>
                          </Card.Body>
                        </div>
                      </div>
                    </Card>
                  </Link>
                </Col>
              ))}
            </Row>
          )}
        </div>
      </Layout>
    </div>
  );
};

export default Previousquestionpaper;
