import React from 'react'
import Menu from './../Layout/Navside';
import menuItems from './../Layout/Menus'; 

const Studentcourse = () => {
  return (

    <div className="py-4 bg-f9fbff">
      <div className="container">
        <div className="row">
          <div className="col-md-3 col-lg-3">
            <div className="td-sidebar d-none d-md-block">
              <Menu menuItems={menuItems} />
            </div>
          </div>
          <div className="col-md-9 col-lg-9">
            <div> student course component </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default Studentcourse