// menuConfig.js

const Menus = [
    { label: "Dashboard", url: "/user/dashboard" },
    { label: "My Courses", url: "/user/student-course" },
    { label: "My Test Series", url: "/user/my-test-series" },
    { label: "My Profile", url: "/user/my-test-series" },
    { label: "All Course ", url: "/user/my-test-series" },
    // Add more menu items here
  ];
  
  export default Menus; 
  