import React,{useState,useEffect} from "react";
import Layout from "../../../common/Layout";
import PageBanner from "../common/PageBanner";
import image from './../../../assest/img/teacher.jpg';
import { useLocation } from "react-router-dom";
import axios from "axios";
import base_url from "../../../api/bootapi";
const TeacherDetails = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get("rwid");
  const [teacher,setTeacher]  = useState([]);
  const [teacherImage, setTeacherImage] = useState('');
  useEffect(() => {
    getTeacherFromServer();
  }, []);

  const getTeacherFromServer = async () => {
    try {
      const response = await axios.get(`${base_url}/Teacher/${id}`);
      const teacherData = response.data;

      setTeacher(teacherData);

      const imageResponse = await axios.get(`${base_url}/image/TeacherImage/${teacherData.rwid}`, {
        responseType: 'arraybuffer',
      });

      const blob = new Blob([imageResponse.data], { type: imageResponse.headers['Content-Type'] });
      const dataUrl = await blobToDataUrl(blob);
      setTeacherImage(dataUrl);
    } catch (error) {
      console.log("Error fetching teacher data: ", error);
    }
  };

  const blobToDataUrl = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  return (
    <div className="about">
      <Layout>
        <PageBanner
          title="Teacher"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Team"
        />

        <div class="profile-area mt-4">
          <div class="container">
            <div class="profile-box py-4">
              <div class="row">
                <div class="col-lg-4 col-md-4">
                  <div class="image ">
                    <img
                      src={teacherImage}
                      alt=""
                      style={{ height: "auto", width: "100%" }}
                    />
                  </div>
                </div>
                <div class="col-lg-8 col-md-8">
                  <div class="content">
                    <h3>{teacher.name}</h3>
                    <span class="sub-title">{teacher.subject}</span>
                    <p>
                      {teacher.description}
                     </p>
                    <ul class="info">
                      <li>
                        <span>Email:</span><b style={{color:'black'}}> {teacher.email}</b>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default TeacherDetails;
