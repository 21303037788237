import React from "react";


const Child = ({ value, onValueChange }) => {

    

    return (
        <div>
            <p>Value in Child Component: {value}</p>
            <button onClick={() => onValueChange(value + 5)}>Increment</button>
        </div>
    )
} 

export default Child;