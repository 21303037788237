import hardesh from "../../../assest/images/hardesh.jpg";
import "../../../assest/css/about.css";
const SelfIntro = () => {
  return (
    <section className="about-3 section-padding">
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-xl-5 col-lg-4">
            <img src={hardesh} alt="self image" className="myimg" style={{ borderRadius: 10 }} />
          </div>
          <div className="col-lg-7 col-xl-7">
            <p className="text-dark">
              <h2>Er. Hardesh Kumar singh</h2>
              Er. Hardesh Kumar singh, the founder, principal of the classes
              aware of the humble beginning of Studywaves classes in the year
              2016 with just 4 students, is glad to have been able to expand the
              classes, to teach thousands of students today, with more than 40
              staff assisting him.
            </p>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-12">
            <h4>About Studywaves Classes</h4>
            <p className="text-dark">
              Studywaves Classes a well-known, coaching institute running
              successfully since last 7 years in Bareilly (UP) and having 4
              branches in Bareilly.
            </p>
            <p className="text-dark">
              We believe in providing all deserving students in Bareilly and
              around kargaina Badaun road, with the best curriculum and pedagogy
              along with the highest quality of education in coaching the
              students in Senior wing for 11 and 12(PCMB), 9-10 (All subjects
              CBSE) and in junior wing for class 1 to 8 CBSE board All subjects
              and also we provide best quality for various competitive exams
              like UPSSSC, CTET, UPTET, SUPER-TET, SSC-CGL, CHSL, MTS, SSC-GD,
              UP-POLICE, DELHI POLICE, RRB-Group-D, NTPC and Various exams with
              best result in Region.
            </p>
            <p className="text-dark">
              Studywaves Classes brings out of the latent strengths of every
              student so that they feel motivated and applauded for their
              strengths and explore the greater depth of their potential.
            </p>
            <p className="text-dark">
              Studywaves classes is not just another coaching class we believe
              in teamwork and our aim is to provide excellent training in all
              subjects i.e, Mathematics, Physics, Chemistry, Biology and English
              to the level of school and All India Level Entrance Exams.
              Studywaves classes has team of highly qualified and competent
              teachers comprising of experienced faculties, graduates and
              post-graduates from top grade universities. The teaching Faculty
              of the Institute is the real strength behind the achievements we
              made within the short span of time. Our teaching faculty members
              are young and dynamic persons who have the experience in the
              examination and hence can empathize with the needs of the
              candidates and prepare them for the Entrance Examinations.
            </p>
            <p className="text-dark">
              The teachers of SWC have periodical brain-storming sessions among
              themselves to plan their teaching method, monitor their own
              performances, gain feedback from fellow members of the faculty and
              constantly think of providing more effective inputs to students to
              help them perform to their greatest potential.
            </p>
            <p className="text-dark">
              Success is planned it is said. In addition to result oriented
              teaching, the classes conduct Test-series in a systematic manner
              before Board exams to equip the students with the right techniques
              they must follow to ensure an excellent performance in writing
              their answers at the Board.
            </p>
            <p className="text-dark">
              Students prefer Studywaves classes as it is the combination of
              unique features of the programme, dedication of our faculty, child
              centric approach from the management, Regular distribution and on
              time collection of Assignments, test and worksheets will provide
              good environment of study with the feel of competitiveness and we
              also understands the needs of the students and last but not the
              least consistent results throughout the years.
            </p>
            <p className="text-dark">
              Apart from preparing students thoroughly for an outstanding
              performance at the exam, the classes also arranges guest lectures
              and seminars for the personality development of students. Lectures
              on Time Management, Stress Management, Study Skills, Career
              Guidance and achieving success have been found very useful by our
              students over the years.
            </p>
            <p className="text-dark">
              Studywaves Classes strongly believes that the best is yet to come,
              and is always ready to reach higher levels of excellence in the
              years to come.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SelfIntro;
