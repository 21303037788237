import React from 'react';
import { Link } from 'react-router-dom';
import mts from './../assest/ComptitionImages/sscmts.jpeg';

const TestCard = ({ data, classes }) => {

    return (
        <div className={`course-style-5 bg-white  ${classes ? classes : ''}`}>
            <div class="course-header">
                <div class="course-thumb">
                    <img src={mts} alt="Course Thumb" class="img-fluid" />
                    <div class="course-price">
                        {
                            data.price === '0' ?
                                <div className="price current-price">Free</div>
                                :
                                <div className="price current-price">{data.price} rs</div>
                        }
                        {
                            data.oldPrice && <div className="price old-price">{data.oldPrice} rs</div>
                        }
                    </div>
                </div>
            </div>

            <div className="course-content">
                <div className="course-meta meta-style-1">

                    <span className="label">{data.level}</span>
                </div>
               
                <div className="course-meta meta-style-1">

                    <span className="label">{data.level}</span>
                </div>


                <div className="course-footer  d-flex align-items-center justify-content-between">
                    <span className="students"><i className="far fa-user-alt me-2"></i>{data.student} Students</span>
                    <Link to={process.env.PUBLIC_URL + `/course-details/${data.id}`} className="rounded-btn">
                        <i className="fa fa-long-arrow-right"></i>
                    </Link>
                </div>
            </div>
        </div>
    )
}
export default TestCard;