import React from 'react';
import { Link } from 'react-router-dom';

const FooterTwo = () => {
    
   const handleuppage=(e)=>{
    window.scroll(0,0)
   }
    return (
        <>
            <section className="footer">
            <div className="footer-mid">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 me-auto col-sm-8">
                            <div className="footer-logo mb-3">
                                <img src={`${process.env.PUBLIC_URL}/assets/studywavesimg/logo.png`} alt="" className="img-fluid"/>
                            </div>
                            <div className="widget footer-widget mb-5 mb-lg-0">				
                                <p>Studywaves is the Most Trusted Learning Platform</p>
                            </div>
                        </div>

                        <div className="col-xl-2 col-sm-4">
                            <div className="footer-widget mb-5 mb-xl-0">
                                <h5 className="widget-title">Explore</h5>
                                <ul className="list-unstyled footer-links">
                                    <li><Link to="/about">About us</Link></li>
                                    <li><Link to="/contact">Contact us</Link></li>
                                    
                                </ul>
                            </div>
                        </div>
 
                        

                        <div className="col-xl-3 col-sm-4">
                            <div className="footer-widget mb-5 mb-xl-0">
                                <h5 className="widget-title">Address</h5>
                                <ul className="list-unstyled footer-links">
                                    <li><h6 className="text-white"> </h6> <Link to="#"> Main office Above Jk Foam ,Near Kargaina Police Chowki , badaun Road, Bareily</Link> </li>
                                    <li><h6 className="text-white"> </h6> <Link to="#">Branch 2:- house no. 23 near temple,  south city, Badaun road, bareilly.</Link> </li>
                                    <li><h6 className="text-white"> </h6> <Link to="#">Branch 3:- Near Dr. Ajay nigam cilinic,  BDA colony,  jagriti Nagar,  kargaina Bareilly</Link> </li>
                                    <li><h6 className="text-white">Phone</h6><Link to="#">+91 7599666668 , +91 7599666688 <br/> +91 7599666464</Link></li>
                                    <li><h6 className="text-white">Email</h6><Link to="#">info@studywavesclasses.com</Link></li>
                                </ul>
                                <div className="footer-socials mt-4">
                                <Link to="https://www.facebook.com/studywaves.in" target="_blank"><i className="fab fa-facebook-f"></i></Link>
                                <Link to="https://wa.me/7599666668" target="_blank"><i className="fab fa-whatsapp" style={{ fontSize: '18px' }}></i></Link>
                                <Link to="https://t.me/StudywavesIndia" target="_blank"><i className="fab fa-telegram" style={{ fontSize: '18px' }}></i></Link>                                
                                <Link to="https://www.instagram.com/hardeshchauhan?igsh=MWF4MzBiOG9kNTY2dg==" target="_blank"><i className="fab fa-instagram" style={{ fontSize: '18px' }}></i></Link>
                                <Link to="https://www.youtube.com/@studywavesclasses007" target="_blank"><i className="fab fa-youtube" style={{ fontSize: '18px' }}></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-sm-4" >
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3512.053235711246!2d79.3950898!3d28.3269906!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39a000964ed19f89%3A0x84a96451130dc2d9!2sStudywaves%20classes%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1709637577033!5m2!1sen!2sin" width="300" height="250" style={{  border:'3px solid white', outline: '2px solid white',outlineOffset:'4px' }}  allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>

                        
                    </div>
                </div>
            </div>

            <div className="footer-btm">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-sm-12 col-lg-6">
                            <p className="mb-0 copyright text-sm-center text-lg-start">© 2023  All rights reserved by <a href="https://marketosindia.com" rel="nofollow">Marketosindia</a> </p>
                        </div>
                        <div className="col-xl-6 col-sm-12 col-lg-6">
                            <div className="d-flex justify-content-between  text-start text-sm-center text-lg-end">
                                <Link to="/legal" className="text-secondary">Legal</Link>
                                <Link to="/refund" className="text-secondary">Refund</Link>
                                <Link to="/contact" className="text-secondary">Supports</Link>
                                <Link to="/terms" className="text-secondary">Terms</Link>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>

            <div className="fixed-btm-top">
                <Link to="#" className="js-scroll-trigger scroll-to-top"><i className="fa fa-angle-up" onClick={handleuppage} ></i></Link>
            </div>
            
        </section>
        </>
    )
}

export default FooterTwo;