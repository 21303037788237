import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import base_url from '../../../api/bootapi';


const CourseCat01=()=>{

    const[images,setImages]=useState([]);
    const[category,setCategory]=useState([]);

    useEffect(()=>{
        getCategoryFromServer();
    },[]);

    const getCategoryFromServer = async ()=>{
        try{
            const response = await axios.get(`${base_url}/Category`);
            const cat = response.data;
            setCategory(cat);
            const imagePromises = cat.map(async (category)=>{
                const imageResponse = await axios.get(`${base_url}/image/CategoryImage/${category.rwid}`,{
                    responseType:'arraybuffer'
                });
                const blob =new Blob([imageResponse.data],{type:imageResponse.headers['content-type']});
                const dataUrl = await blobToDataUrl(blob);
                
                return dataUrl;
            });

            const imageResults = await Promise.all(imagePromises);
            setImages(imageResults);
            
        }catch(error){
            console.log("Some error is occured to fetching the category details "+error)
        }
    };

    const blobToDataUrl = (blob)=>{
        return new Promise((resolve,reject)=>{
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result);
              };
              reader.onerror = reject;
              reader.readAsDataURL(blob);
        });
    };

        return (    
            
            <section className="section-padding">
                <div className="container-fluid container-grid">
                    <div className="row justify-content-center">
                        <div className="col-xl-8">
                            <div className="heading mb-50 text-center">
                                <span className="subheading">Top Categories</span>
                                <h2>Browse Batch By Category</h2>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                    { 
                     category.map((data,i)=> (
                            <div className="col-xl-3 col-lg-4 col-md-6" key={i}>
                                <div className="single-course-category style-2 ">
                                    <div className="course-cat-icon">
                                        <img src={images[i]} alt="" className="img-fluid"/>
                                    </div>
                                    <div className="course-cat-content">
                                        <h4 className="course-cat-title">
                                            <Link to={{ pathname: '/comptiton-course', search: `?catcd=${data.catcd}` }}>{data.name}</Link>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        ))
                     }
                        
                    </div>
                </div>
            </section>

        );
    }

export default CourseCat01;