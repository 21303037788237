import React from 'react';
import { PageBanner}  from '../layouts/course02/index';
import Previousquestionpaper  from '../layouts/PreviousYear/PreviousYearquestion';
import Layout from '../../common/Layout';

const PreviousyearCategory = () =>  {
    return (
        <div className="page">       
            <Layout>     
                <PageBanner
                    title="Previous-Year-Question"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Previous Year Question"
                    />
               <Previousquestionpaper/>
            </Layout>  
        </div>
    );
}

export default PreviousyearCategory
