import React, { useState, useContext, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import AccordionContext from "react-bootstrap/AccordionContext";
import CourseData from "../../../data/course/CourseData.json";

// import InstructorData from "../../../data/instructor/InstructorData.json";
import CurriculumTabContent from "../../../data/course/CurriculumTabContent.json";

import { slugify } from "../../../utils/index";
import RelatedCourses from "../courseDetails/RelatedCourses";
import CourseInfo from "../courseDetails/CourseInfo";
import axios from "axios";
import base_url from "../../../api/bootapi";

const CustomToggle = ({ children, eventKey }) => {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(eventKey);
  const isCurrentEventKey = activeEventKey === eventKey;
  return (
    <button
      type="button"
      onClick={decoratedOnClick}
      aria-expanded={isCurrentEventKey ? true : false}
      className="edu-accordion-button"
    >
      {children}
    </button>
  );
};

const CourseDetails = () => {
  const { id } = useParams();
  const [courseData, setCourseData] = useState(null);
  const [teacher, setTeacher] = useState([]);

  useEffect(() => {
    fetchCourse();
  }, []);

  const fetchCourse = () => {
    const url = `${base_url}/Course/${id}`;
    axios
      .get(url)
      .then((response) => {
        const dbCourse = response.data;
        const courseImageUrl = `${base_url}/Course/CourseImage/${dbCourse.rwid}`;
        setCourseData({ ...dbCourse, courseImageUrl });
        
        console.log(response.data);
      })
      .catch((error) => {
        console.log("error is occured to fetching the course " + error);
      });
  };

  useEffect(() => {
    if (courseData) {
        const teacherRequests = courseData.teacher.map((teacher) => {
            return axios.get(`${base_url}/TeacherByCd/${teacher.teacherCode}`)
                .then((response) => {
                    const teacherData = response.data;
                    const teacherImageUrl = `${base_url}/image/TeacherImage/${teacherData.rwid}`;
                    return { ...teacherData, teacherImageUrl };
                
                })
                .catch((error) => {
                    console.log("Some error occurred while fetching teacher data => ", error);
                    return null; // Return null in case of error
                });
        });

        // Execute all axios requests concurrently
        Promise.all(teacherRequests)
            .then((teachersData) => {
                setTeacher(teachersData.filter((teacher) => teacher !== null)); // Filter out null values
            });
    }
}, [courseData]);
//   console.log(courseData);
//   console.log(teacher);

  const courseId = parseInt(id, 10);
  const data = CourseData.filter((course) => course.id === courseId);
  const courseItem = data[0];

//   const indexOfInstructor = courseData?.findIndex(function (instructor) {
//     return slugify(instructor.name) === slugify(courseItem.instructor);
//   });
//   const instructor = courseData[indexOfInstructor];
//   const instructorExcerpt = instructor.details.substring(0, 190) + "...";
//   const instructorThumb = courseData[indexOfInstructor].image;

  const [contentTab, setContentTab] = useState("overview");
  const handleTab = (content) => {
    if (content === "overview") {
      setContentTab("overview");
    } else if (content === "curriculum") {
      setContentTab("curriculum");
    } else if (content === "instructor") {
      setContentTab("instructor");
    } else if (content === "reviews") {
      setContentTab("reviews");
    }
  };

  return (
    <>
      <section className="page-wrapper">
        <div className="tutori-course-content tab-style-1">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-xl-8">
                <div className="course-details-header mb-5 ">
                  <h1 className="course-title">{courseData?.title}</h1>
                </div>

                <nav className="course-single-tabs learn-press-nav-tabs">
                  <ul className="nav nav-tabs course-nav" role="tablist">
                    <li className="nav-item">
                      <a
                        className={
                          contentTab === "overview"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        type="button"
                        aria-label="Overview"
                        onClick={() => handleTab("overview")}
                      >
                        Course Description
                      </a>
                    </li>

                    <li className="nav-item">
                      <a
                        className={
                          contentTab === "instructor"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        type="button"
                        aria-label="Instructor"
                        onClick={() => handleTab("instructor")}
                      >
                        Instructor
                      </a>
                    </li>
                    <li className="nav-item"></li>
                    <li className="nav-item"></li>
                  </ul>
                </nav>

                <div className="tab-content tutori-course-content">
                  {/* Overview Tab */}
                  {contentTab === "overview" && (
                    <div
                      className={`tab-pane fade show ${
                        contentTab === "overview" ? "active" : ""
                      } `}
                    >
                      <div className="single-course-details ">
                        {/* <div className="course-tab-content" dangerouslySetInnerHTML={{__html: courseItem.details}} /> */}
                        <div
                          className="course-tab-content"
                          dangerouslySetInnerHTML={{
                            __html: courseData?.details,
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {contentTab === "instructor" && (
                  <div className={`tab-pane fade show ${contentTab === "instructor" ? "active" : ""} `}>
                      <div className="course-tab-content">
                        <div className="courses-instructor">
                        {teacher.map((tch, index) => (
                          <div className="single-instructor-box mt-2" key={index}>
                            <div className="row align-items-center">
                              <div className="col-lg-4 col-md-4">
                                <div className="instructor-image">
                                  <img
                                    // src={`${process.env.PUBLIC_URL}/assets/images/team/${instructor.imageLg}`}
                                    // src={tchr?.teacherImageUrl}
                                    src={tch.teacherImageUrl}
                                    alt="Author Thumb"
                                  />
                                </div>
                              </div>

                              <div className="col-lg-8 col-md-8">
                                <div className="instructor-content">
                                  <h4>
                                    <Link
                                      to={
                                        process.env.PUBLIC_URL +
                                        `/instructor-details/${slugify(
                                        //   courseItem.instructor
                                        tch.name
                                        )}`
                                      }
                                    >
                                      {/* {instructor.name} */}
                                      {tch.name}
                                    </Link>
                                  </h4>
                                  <span className="sub-title">
                                    {/* {instructor.designation} */}
                                    {tch.subject}
                                  </span>

                                  {/* <p>{instructorExcerpt}</p> */}

                                </div>
                              </div>
                            </div>
                          </div>
                            ))}
                        </div>
                        {/* COURSE AUTHOR END */}
                      </div>
                    </div>
                    
                  )}
                </div>
              </div>

              <div className="col-lg-5 col-xl-4">
                {/* <CourseInfo data={courseItem} /> */}
                <CourseInfo data={courseData}/>
              </div>
            </div>
          </div>
        </div>

        {/*  Related course */}
        {/* <RelatedCourses courseID={courseData?.rwid} /> */}
      </section>
    </>
  );
};
export default CourseDetails;
