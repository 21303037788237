import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
import axios from 'axios';
import base_url from '../../../api/bootapi';

const Slider = () => {
  const [index, setIndex] = useState(0);
  const [images, setImages] = useState([]);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    document.title = "Home";
    fetchCourseAndImages();
  }, []);

  const fetchCourseAndImages = async () => {
    try {
      const response = await axios.get(`${base_url}/Course`);
      const courses = response.data;

      const imagePromises = courses.slice(0, 5).map(async (course) => {
        const imageResponse = await axios.get(`${base_url}/Course/CourseImage/${course.rwid}`, {
          responseType: 'arraybuffer',
        });

        const blob = new Blob([imageResponse.data], { type: imageResponse.headers['Content-Type'] });
        const dataUrl = await blobToDataUrl(blob);

        return dataUrl;
      });

      const imageResults = await Promise.all(imagePromises);
      setImages(imageResults);
    } catch (error) {
      console.log('Error fetching courses or images: ', error);
    }
  };

  const blobToDataUrl = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect} interval={2000}>
      {images.map((image, idx) => (
        <Carousel.Item key={idx} className="carousel-item">
          <img className="d-block w-100" src={image} alt={`Slide ${idx + 1}`} style={{ maxHeight: '400px' }} />
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default Slider;



