import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Nav from "./Nav";
import Cookies from "js-cookie";

const HeaderTwo = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const openMenu = () => {
    setIsMenuOpen(true);
  };
  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const [user, setUser] = useState(null);

  const userfunction = () => {
    const authToken = Cookies.get("user");
    let userData = null;
    if (authToken && authToken !== "undefined") {
      userData = JSON.parse(authToken);
    }

    // Cookies.remove("user");

    setUser(userData);
  };

  useEffect(() => {
    userfunction();
  }, []);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <header className="header-style-1">
        <div className="header-topbar topbar-style-1">
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-xl-8 col-lg-8 col-sm-6">
                <div className="header-contact text-center text-sm-start text-lg-start">
                  <Link to="#">
                    Main office Above Jk Foam ,Near Kargaina Police Chowki ,
                    badaun Road, Bareily
                  </Link>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-sm-6">
                <div className="header-socials text-center text-lg-end">
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <Link to="https://www.facebook.com/studywaves.in" target="_blank">
                        <i className="fab fa-facebook-f"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                        <a href="https://wa.me/7599666668" target="_blank">
                        <i className="fab fa-whatsapp" style={{ fontSize: '18px' }}></i>
                        </a>
                    </li>

                    <li className="list-inline-item">
                      <a href="https://t.me/StudywavesIndia" target="_blank">
                          <i className="fab fa-telegram" style={{ fontSize: '18px' }}></i>
                      </a>
                  </li>

                  <li className="list-inline-item">
                      <Link to="https://www.instagram.com/hardeshchauhan?igsh=MWF4MzBiOG9kNTY2dg==" target="_blank">
                        <i className="fab fa-instagram" style={{ fontSize: '18px' }}></i>
                      </Link>
                    </li>

                    <li className="list-inline-item">
                      <Link to="https://www.youtube.com/@studywavesclasses007" target="_blank">
                        <i className="fab fa-youtube" style={{ fontSize: '18px' }}></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`header-navbar navbar-sticky ${
            windowWidth <= 991 ? "mobile-menu" : ""
          }`}
        >
          <div className="container">
            <div className="d-flex align-items-center justify-content-between">
              <div className="site-logo">
                <Link to="/">
                  <img
                    style={{ height: "4.8rem", width: "7rem" }}
                    src={`${process.env.PUBLIC_URL}/assets/studywavesimg/logo.png`}
                    alt="logo"    
                    class="img-fluid"
                  />
                </Link>
              </div>

              <div className="offcanvas-icon d-block d-lg-none">
                <a href="#" onClick={openMenu} className="nav-toggler">
                  <i className="fal fa-bars"></i>
                </a>
              </div>

              <nav
                className={`site-navbar ms-auto ${isMenuOpen ? "menu-on" : ""}`}
              >
                <Nav />

                {isMenuOpen && (
                  <a href="#" onClick={closeMenu} className="nav-close">
                    <i className="fal fa-times"></i>
                  </a>
                )}
              </nav>

              {user === null || user.userName === "" ? (
                <div className="header-btn d-none d-lg-block">
                  <Link to="/login" className="login">
                    Login
                  </Link>
                  <Link
                    to="/register"
                    className="btn btn-main-2 btn-sm-2 rounded"
                  >
                    Sign up
                  </Link>
                </div>
              ) : user.userType==='admin'?(
                <div className="header-btn d-none d-lg-block">
                  <Link
                    to="/admin"
                    className="btn btn-main-2 btn-sm-2 rounded"
                  >
                    My Dashboard
                  </Link>
                </div>
              ):(
                <div className="header-btn d-none d-lg-block">
                  <Link
                    to="/user-header"
                    className="btn btn-main-2 btn-sm-2 rounded"
                  >
                    My Dashboard
                  </Link>
                </div>
              )
              }
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default HeaderTwo;
