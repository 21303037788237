import React from "react";
import { Card } from "react-bootstrap";
const Legal = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12 mt-4">
            <div className="mt-3">
              <p>
                Last Updated: March 27, 2024 This Privacy Policy describes how
                Studywavesclasses collects, uses, and shares your information
                when you use our website https://studywavesclasses.com or
                interact with us. We are committed to protecting the privacy and
                security of your personal information in accordance with the
                General Data Protection Regulation (GDPR) and other applicable
                data protection laws. By accessing or using our Website, you
                agree to the terms of this Privacy Policy. If you do not agree
                with this Privacy Policy, please do not use our Website.
              </p>
            </div>

            <div className="mt-3">
              <h4>1. Information We Collect</h4>
              <ul>
                <li className="mb-2">
                  We collect the following types of information when you use our
                  Website:
                </li>

                <li className="mb-2">
                  <h5>Personal Information:</h5>
                  <li>
                    • When you purchase our online courses, we may collect
                    personal information such as your name, email address,
                    billing address, and payment information.
                  </li>
                </li>

                <li className="mb-2">
                  <h5>Non-Personal Information:</h5>
                  <li>
                    • We may collect non-personal information such as your IP
                    address, browser type, operating system, and browsing
                    behavior on our Website.
                  </li>
                </li>

                <li className="mb-2">
                  <h5>Cookies:</h5>
                  <li>
                    • We use cookies and similar tracking technologies to
                    enhance your browsing experience and analyze usage patterns
                    on our Website. You can control cookies preferences through
                    your browser settings.
                  </li>
                </li>
              </ul>
            </div>

            <div className="mt-4">
              <h4>2. How We Use Your Information:</h4>
              <p>
                We use the information we collect for the following purposes:
              </p>
              <ul>
                <li>
                  • To provide and maintain our online courses and services.
                </li>
                <li>• To process your orders and payments.</li>
                <li>
                  • To communicate with you regarding your account, purchases,
                  and updates about our services.
                </li>
                <li>
                  • To analyze and improve the functionality of our Website and
                  services.
                </li>
                <li>
                  • To comply with legal obligations and protect our rights.
                </li>
              </ul>
            </div>

            <div className="mt-4">
              <h5>3. How We Share Your Information:</h5>
              <p>
                We may share your information in the following circumstances:
              </p>
              <ul>
                <li>
                  • With third-party service providers who assist us in
                  delivering our services (e.g., payment processors, IT service
                  providers).
                </li>
                <li>
                  • With our affiliates, subsidiaries, or successors in
                  connection with a business transaction (e.g., merger,
                  acquisition).
                </li>
                <li>
                  • When required by law or in response to legal process (e.g.,
                  court order, subpoena).
                </li>
                <li>
                  • With your consent or as otherwise disclosed at the time of
                  data collection.
                </li>
              </ul>
            </div>

            <div className="mt-4">
              <h5>4. Data Retention:</h5>
              <ul>
                <li>
                  We will retain your personal information for as long as
                  necessary to fulfill the purposes outlined in this Privacy
                  Policy, unless a longer retention period is required or
                  permitted by law.
                </li>
              </ul>
            </div>

            <div className="mt-4">
              <h5>5. Your Rights:</h5>
              <p>
                You have the following rights regarding your personal
                information under the GDPR:
              </p>
              <ul>
                <li>
                  • Right to access: You have the right to request access to the
                  personal information we hold about you.
                </li>
                <li>
                  • Right to rectification: You have the right to request
                  correction of inaccurate or incomplete personal information.
                </li>
                <li>
                  • Right to erasure: You have the right to request deletion of
                  your personal information under certain circumstances.
                </li>
                <li>
                  • Right to restrict processing: You have the right to restrict
                  the processing of your personal information under certain
                  circumstances
                </li>
                <li>
                  • Right to data portability: You have the right to receive
                  your personal information in a structured, commonly used, and
                  machine-readable format.
                </li>
                <li>
                  • Right to object: You have the right to object to the
                  processing of your personal information under certain
                  circumstances, including for direct marketing purposes.
                </li>
              </ul>
              <p>
                To exercise any of these rights, please contact us using the
                contact information provided below.
              </p>
            </div>

            <div className="mt-4">
              <h5>6. Security:</h5>
              <ul>
                <li>
                  We implement appropriate technical and organizational measures
                  to protect your personal information against unauthorized
                  access, disclosure, alteration, or destruction.
                </li>
              </ul>
            </div>

            <div className="mt-4">
              <h5>7. Contact Us:</h5>
              <p>
                If you have any questions or concerns about this Privacy Policy
                or our data practices, please contact us at: Email:
                info@studywavesclasses.com Address: Main office Above Jk Foam,
                Near Kargaina Police Chowki, Badaun Road, Bareilly, India Branch
                2: House no. 23 near temple, south city, Badaun road, Bareilly,
                India Branch 3: Near Dr. Ajay Nigam Clinic, BDA colony, Jagriti
                Nagar, Kargaina, Bareilly, India Phone: +91 7599666668, +91
                7599666688, +91 7599666464
              </p>
            </div>

            <div className="mt-4">
              <h5>Changes to this Privacy Policy:</h5>
              <p>We may update this Privacy Policy from time to time by posting a revised version on our Website. Please review this Privacy Policy periodically for any changes.</p>
            </div>

            <div className="mt-4">
              <h5>9. Changes to Terms:</h5>
              <p>
                • We reserve the right to update or modify these Terms at any
                time without prior notice. Changes to these Terms will be
                effective upon posting on our Website. Your continued use of our
                Website after any such changes constitutes acceptance of the
                revised Terms.
              </p>
            </div>

            <div className="mt-4 mb-5">
              <h5>Effective Date:</h5>
              <p>
              This Privacy Policy is effective as of March 27, 2024.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Legal;
