import React from 'react'
import Layout from './../common/Layout';
import PageBanner from '../components/layouts/common/PageBanner';
import TestGrid from'./TestGrid';
const Testseries = () => {
    return (
        <div className="contact-page">
            <Layout>
                <PageBanner
                    title="Test Series"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Test Series"
                />
                <TestGrid  />
                PageBanner
            </Layout>
        </div>
    )
}

export default Testseries