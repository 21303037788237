import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom/dist';
import { PageBanner } from '../layouts/courses/index';
import Layout from '../../common/Layout';
import Spinner from 'react-bootstrap/Spinner';  // Import Spinner from Bootstrap
import './Class.css';

import class1 from './../../assest/classImages/Class1.png';
import class2 from './../../assest/classImages/Class2.png';
import class3 from './../../assest/classImages/Class3.png';
import class4 from './../../assest/classImages/Class4.png';
import class5 from './../../assest/classImages/Class5.png';
import class6 from './../../assest/classImages/Class6.png';
import class7 from './../../assest/classImages/Class7.png';
import class8 from './../../assest/classImages/Class8.png';
import class9 from './../../assest/classImages/Class9.png';
import class10 from './../../assest/classImages/Class10.png';
import class11 from './../../assest/classImages/Class11.png';
import class12 from './../../assest/classImages/Class12.png';

const Class = () => {
  const [loading, setLoading] = useState(true);
  const [classes, setClasses] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      setClasses([
        { id: 1, image: class1 },
        { id: 2, image: class2 },
        { id: 3, image: class3 },
        { id: 4, image: class4 },
        { id: 5, image: class5 },
        { id: 6, image: class6 },
        { id: 7, image: class7 },
        { id: 8, image: class8 },
        { id: 9, image: class9 },
        { id: 10, image: class10 },
        { id: 11, image: class11 },
        { id: 12, image: class12 },
      ]);
      setLoading(false);
    }, 1000); 
  }, []);

  const rows = [];
  for (let i = 0; i < classes.length; i += 4) {
    rows.push(classes.slice(i, i + 4));
  }

  return (
    <div className="page">
      <Layout>
        <PageBanner
          title="Academic Course"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Academic Course"
        />

        <div className="container mb-4" style={{ marginTop: '4rem' }}>
          {loading ? (
           
            <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            rows.map((row, rowIndex) => (
              <Row key={rowIndex} className="mb-4">
                {row.map((classInfo) => (
                  <Col lg={3} sm={6} xs={6} md={3} key={classInfo.id}>
                    <Link to={{ pathname: `/subject/${classInfo.id}` }}>
                      <div
                        className="single-categories-box"
                        style={{
                          transition: 'transform 0.3s, box-shadow 0.3s',
                          cursor: 'pointer',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          height: '100%',
                        }}
                        onMouseOver={(e) => {
                          e.currentTarget.style.transform = 'scale(1.1)';
                          e.currentTarget.style.boxShadow = '0px 4px 8px rgba(0, 0, 0, 0.3)';
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.style.transform = 'scale(1)';
                          e.currentTarget.style.boxShadow = 'none';
                        }}
                      >
                        <img src={classInfo.image} alt={`Class Logo`} className='classimage' />
                      </div>
                    </Link>
                  </Col>
                ))}
              </Row>
            ))
          )}
        </div>
      </Layout>
    </div>
  );
};

export default Class;
