import React from "react";
import { Card } from "react-bootstrap";
const Terms = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12 mt-4">
            <div className="mt-3"> 
            <p>
              Last Updated: March 27, 2024 These Terms and Conditions govern
              your use of the website https://studywavesclasses.com and the
              online courses provided by StudyWavesClasses. By accessing or
              using our Website and services, you agree to comply with these
              Terms. If you do not agree with these Terms, please do not use our
              Website or purchase our courses.
            </p>
            </div>

            <div className="mt-3">
            <h5>1. Use of Website:</h5>
                <ul>
                  <li>
                    • You must be at least 18 years old to use our Website and
                    services. By using our Website, you represent that you are
                    at least 18 years old.
                  </li>
                  <li>
                    • You agree to provide accurate and complete information
                    when using our Website, including when creating an account
                    and making purchases.
                  </li>
                </ul>
            </div>

            <div className="mt-4">
              <h5>2. Online Courses:</h5>
              <ul>
                <li>
                  • We offer online courses for individuals preparing for
                  government exams in India. The content of our courses is for
                  educational purposes only and does not guarantee success in
                  any specific exam.
                </li>
                <li>
                  • You may purchase our courses through our Website. Payment
                  terms and refund policies are specified on our Website and may
                  vary based on the course.
                </li>
                <li>
                  • Your access to our courses is non-transferable and may not
                  be shared with others. You agree not to distribute, reproduce,
                  or modify the course content without our prior written
                  consent.
                </li>
              </ul>
            </div>
            
            <div className="mt-4">
              <h5>3. Intellectual Property</h5>
              <ul>
                <li>
                •	All content available on our Website, including course materials, text, graphics, logos, images, and videos, are the intellectual property of StudyWavesClasses or its licensors and are protected by copyright laws.
                </li>
                <li>
                •	You may not use our intellectual property for any unauthorized purpose, including commercial use, without our express written consent.
                </li>
              </ul>
            </div>
            
            <div className="mt-4">
              <h5>4. Privacy</h5>
              <ul>
                <li>
                •	Your privacy is important to us. Please review our Privacy Policy to understand how we collect, use, and protect your personal information.
                </li>
              </ul>
            </div>

            <div className="mt-4">
              <h5>5. Disclaimer of Warranties</h5>
              <ul>
                <li>
                •	We do not guarantee any specific results from using our courses. Your success in exams depends on various factors, including your effort and abilities.
                </li>
              </ul>
            </div>

            <div className="mt-4">
              <h5>6. Limitation of Liability</h5>
              <ul>
                <li>
                •	To the fullest extent permitted by law, we shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or related to your use of our Website or courses.
                </li>
              </ul>
            </div>


            <div className="mt-4">
                <h5>7. Indemnification</h5>
              
                <ul>
                  <li>
                  •	You agree to indemnify and hold harmless StudyWavesClasses and its affiliates, directors, officers, employees, and agents from any claims, liabilities, damages, or expenses arising out of your use of our Website or courses.
                  </li>
                </ul>
            </div>

            <div className="mt-4">
              <h5>8. Governing Law and Jurisdiction</h5>
              <ul>
                <li>
                •	These Terms shall be governed by and construed in accordance with the laws of India. Any disputes arising under these Terms shall be subject to the exclusive jurisdiction of the courts in Bareilly, Uttar Pradesh, India.
                </li>
              </ul>
            </div>

            <div className="mt-4">
              <h5>9. Changes to Terms:</h5>
              <p>
                • We reserve the right to update or modify these Terms at any
                time without prior notice. Changes to these Terms will be
                effective upon posting on our Website. Your continued use of our
                Website after any such changes constitutes acceptance of the
                revised Terms.
              </p>
            </div>

            <div className="mt-4 mb-5">
              <h5>10. Contact Us:</h5>
              <p>
                If you have any questions or concerns about these Terms, please
                contact us at: Email: info@studywavesclasses.com Address: Main
                office Above Jk Foam, Near Kargaina Police Chowki, Badaun Road,
                Bareilly, India Branch 2: House no. 23 near temple, south city,
                Badaun road, Bareilly, India Branch 3: Near Dr. Ajay Nigam
                Clinic, BDA colony, Jagriti Nagar, Kargaina, Bareilly, India
                Phone: +91 7599666668, +91 7599666688, +91 7599666464
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Terms;
