import axios from 'axios';
import React, { Component, useState } from 'react';
import Cookies from 'js-cookie';
import { Link, useNavigate } from 'react-router-dom';
import base_url from '../../../api/bootapi';
import Swal from 'sweetalert2';

const LoginForm =()=> {

   
    const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit =  (e) => {
        e.preventDefault();

        let response = axios.get(`${base_url}/login`,{
            params: {
                username: username,
                password: password
            }
        }).then((response)=>{
            if(response.data!=""){
            const user = response.data;
            const userData = JSON.stringify(user)
            console.log(userData);
            Cookies.set('user', userData, { expires: 1 });
                if (user.userType === 'admin') {
                    navigate('/admin');
                } else {
                    navigate('/user-header');  
                }
            }
            else{
                Swal.fire({icon:'error',title:"Error!",text:"Username or password is wrong"});
            }
        }).catch((error)=>{
            Swal({icon:'error',title:"error!",text:"some error is occured"});
            console.log("some error is occured for fetching the data of user "+error)
        });
       
    }
       

        return (
            <section className="page-wrapper single ">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-6 col-xl-6">
                            <div className="login-form">
                                <div className="form-header">
                                    <h2 className="font-weight-bold mb-3">Login</h2>
                
                                    <div className="info">
                                        <span>Don't have an account yet?  </span>
                                        <Link to="/register" className="text-decoration-underline">Sign Up for Free</Link>
                                    </div>
                                </div>

                                <form onSubmit={handleSubmit} >
                                    <div className="form-row">
                                        <label>Username &nbsp;<span className="required" style={{color:'red'}}>*</span></label>

                                        <input type="text" className="form-control" placeholder="Username or Email" onChange={(e) => setUsername(e.target.value)}/>
                                    </div>

                                    <div className="form-row">
                                        <label>Password&nbsp;<span className="required" style={{color:'red'}}>*</span></label>

                                        <input className="form-control" type="password" placeholder="Password"   onChange={(e) => setPassword(e.target.value)}/>
                                    </div>
                                
                                    <div className="d-flex align-items-center justify-content-between py-2">
                                        <div className="form-row">
                                            <input type="checkbox" /> <span>Remember me</span>
                                        </div>
                
                                        <p className="lost_password">
                                            <Link to="#">Forgot password?</Link>
                                        </p>
                                    </div>
                
                                    <div className="form-row">
                                        <button type="submit">Log in</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        );
    }


export default LoginForm;