import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Spinner } from 'react-bootstrap';
import class1 from './../../assest/classImages/books1.png';
import { PageBanner } from '../layouts/courses/index';
import Layout from '../../common/Layout';
import { Link } from 'react-router-dom/dist';
import pdf from "./../../assest/pdf/law.12.pdf"
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import base_url from '../../api/bootapi';

const Lession = () => {
  const [lession, setLession] = useState([]);     
  const [loading, setLoading] = useState(true);
  const location  = useLocation();
  const params =  new URLSearchParams(location.search);
  const subjectid = params.get("subjectid");

  const openPdfInNewTab = async (pdfLink) => {
    try {
      const response = await fetch(pdfLink);
  
      if (!response.ok) {
        throw new Error(`Failed to fetch PDF. Status: ${response.status}`);
      }
  
      const blob = await response.blob();
      const dataUrl = URL.createObjectURL(blob);
      // Open the PDF in a new tab
      const newTab = window.open(dataUrl, "_blank");

      if (newTab) {
        newTab.document.title = title || 'PDF Document';
        newTab.focus();
      }
    } catch (error) {
      console.error(`Error opening PDF: ${error.message}`);
    }
  };



  useEffect(() => {

    const fetchData = async ()=>{
      try{
          const response =await axios.get(`${base_url}/Lession/LessionBySubject/${subjectid}`);
          const lessions =  response.data.map((lsn)=>({
              ...lsn,
              imageUrl: class1,
              pdfLink: `${base_url}/Lession/image/Lession_PDF/${lsn.rwid}` 
          }));
        setLession(lessions);
          setLoading(false);
      }
      catch(error){
        console.log("Some error is occured to fetching the data of Lession "+error);
        setLoading(false);
      }
    };
    
    fetchData();
  }, [subjectid]);

  return (
    <div className="page">
      <Layout>
        <PageBanner title="Academic Course" rootUrl="/" parentUrl="Home" currentUrl="Academic Course" />
        <div className="container" style={{ marginTop: '4rem', marginBottom: '4rem' }}>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '300px' }}>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <Row>
             
              {lession.map((lessionItem) => (
                <Col key={lessionItem.rwid} md={4} sm={6} xs={12} className="mb-4">
                   <Link to={`/lession?subjectid=${subjectid}`} onClick={() => openPdfInNewTab(lessionItem.pdfLink)}>
                  <Card>
                    <div className="d-flex">
                      <div style={{ flex: '30%', overflow: 'hidden' }}>
                        <Card.Img src={lessionItem.imageUrl} alt={`Subject ${lessionItem.lession}`} style={{ width: '100%' }} />
                      </div>
                      <div style={{ flex: '70%' }}>
                        <Card.Body style={{ height: '60px', display: 'flex', alignItems: 'center' }}>
                          <Card.Title>{lessionItem.lession}</Card.Title>
                        </Card.Body>
                      </div>
                    </div>
                  </Card>
                  </Link>
                </Col>
              ))}
              
            </Row>
            
          )}
        </div>
      </Layout>
    </div>
  );
};

export default Lession;
